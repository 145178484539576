import React, { useState, useEffect } from 'react';

const Toggle = ({ type, id, game, teams }) => {
    const [data, setData] = useState([]);

    const buildLeader = async (leaderData, past) => {
        const passing = await athlete(leaderData.categories[0].leaders[0].athlete.$ref);
        const rushing = await athlete(leaderData.categories[1].leaders[0].athlete.$ref);
        const receiving = await athlete(leaderData.categories[2].leaders[0].athlete.$ref);

        const passStat = (<div>{leaderData.categories[0].leaders[0].displayValue.split(',').slice(1).join(',')}{past && <><br />(2023)</>}</div>);
        const rushStat = (<div>{leaderData.categories[1].leaders[0].displayValue}{past && <><br />(2023)</>} </div>);
        const receStat = (<div>{leaderData.categories[2].leaders[0].displayValue}{past && <><br />(2023)</>}</div>);

        setData({ passing, rushing, receiving, passStat, rushStat, receStat });
    }

    useEffect(() => {
        const fetchLeader = async () => {
            try {
                let leaderData;

                // First try to fetch from the primary endpoint
                let leaderResponse = await fetch(`https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/events/${game}/competitions/${game}/competitors/${id}/leaders`);

                if (!leaderResponse.ok) {
                    // If primary endpoint fails, try the 2024 season data
                    leaderResponse = await fetch(`https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/2024/types/1/teams/${id}/leaders`);

                    if (!leaderResponse.ok) {
                        setData("FAILED");
                        return;
                    }
                    /*if (!leaderResponse.ok) {
                        // If 2024 season data fails, try the 2023 season data
                        leaderResponse = await fetch(`https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/2023/types/2/teams/${id}/leaders`);

                        
                    }*/
                }

                leaderData = await leaderResponse.json();
                buildLeader(leaderData, leaderResponse.url.includes('2023'));
            } catch (error) {
                console.error('Error fetching leader data:', error);
                setData("FAILED");
            }
        };

        fetchLeader();
    }, [game, id]);


    const athlete = async (ref) => {
        try {

            // Check if the ref starts with 'http://'
            if (ref.startsWith('http://')) {
                // Replace 'http://' with 'https://'
                ref = ref.replace(/^http:\/\//i, 'https://');
            }

            const athleteResponse = await fetch(ref);

            if (!athleteResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const athleteData = await athleteResponse.json();
            return athleteData;
        } catch (error) {
            console.error('Error fetching athlete data:', error);
            // You may choose to rethrow the error here if necessary
        }
    };


    return (
        <div className={type}>
            {data != "FAILED" ? (
                <>
                    <div className="passing">
                        <div className="pic">
                            <img src={data.passing?.headshot?.href} alt={data.passing?.headshot?.alt} />
                        </div>
                        <div className="words">
                            <div className="fullName">
                                {data.passing?.fullName || '\u00A0'}
                            </div>
                            <div className="stat">
                                {data.passStat || " loading... "}
                            </div>
                        </div>
                    </div>
                    <div className="rushing">
                        <div className="pic">
                            <img src={data.rushing?.headshot?.href} alt={data.rushing?.headshot?.alt} />
                        </div>
                        <div className="words">
                            <div className="fullName">
                                {data.rushing?.fullName || '\u00A0'}
                            </div>
                            <div className="stat">
                                {data.rushStat || " loading... "}
                            </div>
                        </div>
                    </div>
                    <div className="receiving">
                        <div className="pic">
                            <img src={data.receiving?.headshot?.href} alt={data.receiving?.headshot?.alt} />
                        </div>
                        <div className="words">
                            <div className="fullName">
                                {data.receiving?.fullName || '\u00A0'}
                            </div>
                            <div className="stat">
                                {data.receStat || " loading... "}
                            </div>
                        </div>
                    </div>
                </>

            ) : (
                <>
                    <div className="passing">
                        <div className="pic">
                            <img />
                        </div>
                        <div className="words">
                            <div className="fullname">
                                <hr />
                            </div>
                            <div className="stat">
                                Not Enough Data
                            </div>
                        </div>
                    </div>
                    <div className="rushing">
                        <div className="pic">
                            <img />
                        </div>
                        <div className="words">
                            <div className="fullname">
                                <hr />
                            </div>
                            <div className="stat">
                                Not Enough Data
                            </div>
                        </div>
                    </div>
                    <div className="receiving">
                        <div className="pic">
                            <img />
                        </div>
                        <div className="words">
                            <div className="fullname">
                                <hr />
                            </div>
                            <div className="stat">
                                Not Enough Data
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );

};


const WinPercent = ({ val, hc, ac, odds, homeWin, inProgress, GameIsOver, liveOdds, ou }) => {

    var homeP = null;
    var title = true;
    
    if (liveOdds == null) {
        if (val == null) {
            if (odds !== null) {
                homeP = Math.round(100 - 10*Math.max(1, Math.min(9, (odds / 5 + 5))));
            }
        }
        else {
            homeP = Math.round(100-val*10);
        }
    }
    else {
        title = false;
        liveOdds = Math.max(1, Math.min(99, (liveOdds * 100)));
        val *= 10;
        val = 100 - val;
        var weight = Math.abs(liveOdds - 50) / 50;
        //liveOdds = (liveOdds * weight + val * (1 - weight));        
        homeP = Math.round(liveOdds);
    }
    console.log(liveOdds);
    console.log(val);
    var O = 5.1 * ((100 - homeP) / 10 - 5);

    O = Math.round(O);

    var OTEXT = "";
    if (O > 0) {
        OTEXT = ("Wins By " + O);
    }
    else if (O < 0){
        OTEXT = ("Wins By " + -O);
    }
    else {
        OTEXT = ("");
    }


    const av = ou / 2;
    const hscore = Math.round(av - O/2);
    const ascore = Math.round(av + O/2);
    const score = (hscore + " - " + ascore);

     /*if (odds < 1 && odds > 0) {
        HomeOdds = (odds * 2 * Math.abs(0.5 - odds) + (val / 10) * 2 * (0.5 - Math.abs(0.5 - odds))) * 10;
        val = null;
        title = false;
    }


    
    var awayP = 0;
   // console.log(odds);
    if (val == null && odds != null) {
        homeP = Math.round(100 - 10 * HomeOdds);
        awayP = Math.round(100 - homeP);
    }
    else if (val != null) {
        homeP = Math.round(100 - 10 * val);
        awayP = Math.round(100 - homeP);

    }
    else {
        homeP = null;
    }
    //console.log(odds);
    const gradient = Math.max(7.5, Math.min(92.5, homeP));*/


    /*const divStyle = {
        background: `linear-gradient(to right, #${hc}, ${gradient - 0.5}%, #${ac}, ${gradient - 0.5}%, white, ${gradient + 0.5}%, #${ac}, ${gradient + 0.5}%, #${ac})`,
        color: 'white',
    };*/

    const homeStyle = {
        background: `#${hc}`,
        color: 'white',
        width: `${homeP}%`,
        transition: '1s ease',
        padding: '2px 5px',
        borderRadius: '100px 0 0 100px',
    }

    const awayStyle = {
        background: `#${ac}`,
        color: 'white',
        width: `${100 - homeP}%`,
        transition: '1s ease',
        padding: '2px 5px',
        borderRadius: '0 100px 100px 0',
    }

    const Radius = 40;
    const stroke = 12;
    const Length2 = (homeP / 100) * (Math.PI * (Radius * 2)) - 0.5;
    const Length = ((100 - homeP) / 100) * (Math.PI * (Radius * 2)) - 0.5;
    

    // Calculate the angle in radians
    const angleRad = Length / Radius;
    const angleRad2 = Length2 / Radius;

    // Calculate the endpoint coordinates based on direction
    const startX = 50;
    const startY = 50 - Radius; // Top of the circle

    const endX = 50 + Radius * Math.sin(angleRad);
    const endX2 = 50 + Radius * Math.sin(-angleRad2);

    const endY = 50 - Radius * Math.cos(angleRad);
    const endY2 = 50 - Radius * Math.cos(angleRad2);
   
    return (
        <div className="predictor-section">
            {title && !GameIsOver && (<h2>Matchup Projection</h2>)}
            {homeP != null && !GameIsOver ? (
                <>
                    {inProgress == 0 ? <svg width="200" height="150" viewBox="0 0 100 100">
                        <path className="animated-path"
                            d={`M ${startX},${startY} 
                                A ${Radius},${Radius} 0 ${angleRad > Math.PI ? 1 : 0},1 ${endX},${endY}`}
                            fill="transparent"
                            stroke={`#${ac}`}
                            strokeWidth={stroke}
                            style={{ strokeDasharray: Length - 1, strokeDashoffset: Length - 1 }}
                        />
                        <path className="animated-path"
                            d={`M ${startX},${startY} 
                                A ${Radius},${Radius} 0 ${angleRad2 > Math.PI ? 1 : 0},0 ${endX2},${endY2}`}
                            fill="transparent"
                            stroke={`#${hc}`}
                            strokeWidth={stroke}
                            style={{ strokeDasharray: Length2-1, strokeDashoffset: Length2-1 }}
                        />
                        {hscore > 0 && ascore > 0 && false ?
                            (<>
                        <foreignObject x={-60} y="25" width="50" height="50">
                            <div xmlns="http://www.w3.org/1999/xhtml" style={{
                                width: '100%',
                                whiteSpace: 'normal',
                                overflowWrap: 'break-word',
                                fontFamily: "'Fugaz One'",
                                color: `white`,
                                fontWeight: '500',
                                backgroundColor: `#${hc}`,
                                height:'45px',
                                textAlign: 'center',
                                fontSize: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '3px',
                                paddingBottom:'5px'
                            }}>
                                {hscore}
                            </div>
                        </foreignObject>
                        <text x="-35" y="65" fill="white" textAnchor="middle" dominantBaseline="central" style={{ fontSize: '9px', opacity: '0.75' }}>SCORE</text>
                        <foreignObject x={110} y="25" width="50" height="50">
                            <div xmlns="http://www.w3.org/1999/xhtml" style={{
                                width: '100%',
                                whiteSpace: 'normal',
                                overflowWrap: 'break-word',
                                fontFamily: "'Fugaz One'",
                                color: `white`,
                                fontWeight: '500',
                                backgroundColor: `#${ac}`,
                                height: '45px',
                                textAlign: 'center',
                                fontSize: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '3px',
                                paddingBottom: '5px'
                            }}>
                                {ascore}
                            </div>
                        </foreignObject>
                        <text x="135" y="65" fill="white" textAnchor="middle" dominantBaseline="central" style={{ fontSize: '9px', opacity:'0.75' }} >SCORE</text>
                        </>
                        ) : null}

                        <text x="33.3" y="50" fill={`#${hc}`} textAnchor="middle" dominantBaseline="central" fontFamily="'Fugaz One'">{homeP}%</text>
                        <text x="66.6" y="50" fill={`#${ac}`} textAnchor="middle" dominantBaseline="central" fontFamily="'Fugaz One'">{100-homeP}%</text>
                        <line x1={50} y1={startY - stroke} x2={50} y2={startY + stroke} stroke="white"
                            strokeWidth={2} />
                        <line x1={50} y1={35} x2={50} y2={65} stroke="#eee"
                            strokeWidth={1} />
                    </svg> :

                        <div className="predictor predictor-test">

                            <div style={homeStyle} className="percent home-percent">{homeP}%</div>
                            <div style={awayStyle} className="percent away-percent">{100 - homeP}%</div>
                        </div>}
                    {title ? (<div className="description">According to <u>GIBBS Power Ratings</u></div>) : (<div className="description">Live Projections From <u>GIBBS Power Ratings</u></div>)}
                </>
            ) : GameIsOver ? null : (<div className="prediction-tbd"><u>View Current GIBBS Power Ratings</u></div>)}

            {/*homeP > 0 && homeP < 100 ? (
                <>
                    
                   <div style={divStyle} className="predictor">
                        <div className="percent home-percent">{homeP}%</div>
                        <div className="percent away-percent">{awayP}%</div>
                    </div>
                     {title ? (<div className="description">According to <u>GIBBS Power Ratings</u></div>) : (<div className="description">Live Projections From ESPN</div>)}
                </>
            ) : (<div className="prediction-tbd"><u>View Current GIBBS Power Ratings</u></div>)*/}
        </div>
    );
}

export { Toggle, WinPercent };